import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}


function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const EstadoResultadosPDF = async (objEstadoResultados, objEmpresa, fecha) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = '#662e91'

            //LOGO
            const rutaImagen = require('../../assets/Logos-03.png');
            doc.addImage(rutaImagen, 'PNG', xCabecera, 5, 58, 20);

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('ESTADO DE RESULTADOS', 210, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text(objEmpresa, 210, yCabecera += 5, { align: 'right' });

            // doc.text(objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
            let periodo = 'PERIODO ' + fecha;
            doc.text(periodo, 210, yCabecera += 5, { align: 'right' });


            // Definir el color de la barra
            const widthT = 205;
            const heightT = 8;
            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(5, yCabecera += 12, widthT, heightT, 'F');
            doc.setTextColor("#fff");
            doc.text('Descripción', 7, yCabecera += 5, { align: 'left' });
            doc.text('Importe', 208, yCabecera, { align: 'right' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            let yLista = yCabecera + 9;
            for (let c of objEstadoResultados) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(9);
                doc.setTextColor("#000");

                let signo = c.signo;
                let descripcion = c.descripcion;
                let importe = c.importe;

                let descripcion_ = doc.splitTextToSize(descripcion, 150);
                let descripcion_Height = doc.getTextDimensions(descripcion_).h;


                if (signo === '=') {
                    doc.setFont('helvetica', 'bold');
                }
                else {
                    doc.setFont('helvetica', 'normal');
                }
                doc.text(signo, 7, yLista, { align: 'left' });
                doc.text(descripcion_, 10, yLista, { align: 'left' });
                doc.text(formatoNumerico(importe), 208, yLista, { align: 'right' });

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yLista > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yLista = 10;
                } else {
                    // const color_ = "#662E91"
                    // doc.setDrawColor(color_);
                    // doc.setLineWidth(.1);
                    // doc.line(5, yLista, 210, yLista);
                }
                yLista += descripcion_Height + 1
            }

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Estado de resultados', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { EstadoResultadosPDF };