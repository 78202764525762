<template>
  <q-page class=" q-pa-lg ">

    <q-tabs v-model="tab" dense class="bg-blue-2" align="justify">
      <q-tab name="estadosResultados" label="Estados de resultados" />
      <q-tab name="estadoSituacionFinanciera" label="Estado de situación financiera " />
    </q-tabs>

    <q-tab-panels v-model="tab" animated>
      <q-separator />
      <q-tab-panel name="estadosResultados">
        <ReporteEstadoResultados></ReporteEstadoResultados>
      </q-tab-panel>
      <q-tab-panel name="estadoSituacionFinanciera">
        <ReporteEstadoSituacionFinanciera></ReporteEstadoSituacionFinanciera>
      </q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import moment from 'moment';
import print from 'print-js'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import ReporteEstadoResultados from '../components/EstadoResultados/ReporteEstadoResultados.vue';
import ReporteEstadoSituacionFinanciera from '../components/EstadoSituacionFinanciera/ReporteEstadoSituacionFinanciera.vue';
export default {
  name: 'Home',
  components: {
    HelloWorld, Money,
    ReporteEstadoResultados, ReporteEstadoSituacionFinanciera
  },
  data() {
    return {
      tab: 'estadosResultados'
    }
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    this.GetClientes();
  },
  methods: {
    async GetClientes() {
      try {
        let response = await axios.get("ControlVentas/GetClientes/estadosFinancieros");
        let catalogo = response.data;
        this.$store.state.listaClientesStore = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
<style>
.logo-inicio {
  background-image: url('../assets/Logos-03.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}
</style>