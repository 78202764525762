<template>
    <q-page>

        <!-- DIALOGO PARA CREAR UN NUEVO -->
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <EstadoResultados @cierraVentana="dialogNuevo = false"></EstadoResultados>
        </q-dialog>

        <div class="row q-col-gutter-sm ">


            <div class="col-12 col-md-4">
                <q-select v-model="cliente" emit-value map-options @filter="filtroClientes" option-label="cliente"
                    :options="itemsfiltroclientes" use-input input-debounce="0" dense filled label="Cliente"
                    virtual-scroll-slice-size="1" />
            </div>
            <div class=" col-12 col-md-2">
                <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año" />
            </div>
            <div class=" col-12 col-md-2">
                <q-select filled dense v-model="selectedMesI" :options="itemsMes" label="Mes inicial" />
            </div>
            <div class=" col-12 col-md-4">
                <q-select filled dense v-model="selectedMesF" :options="itemsMes" label="Mes final">
                    <template v-slot:after>
                        <q-btn round color="primary" icon="mdi-magnify" @click="GetListEstadoResultados()">
                            <q-tooltip>
                                Consultar
                            </q-tooltip>
                        </q-btn>
                        <q-btn round color="green" icon="mdi-microsoft-excel" @click="ExportarExcel()">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn round color="red" icon="mdi-file-pdf-box" @click="ExportarPDF()">
                            <q-tooltip>
                                PDF
                            </q-tooltip>
                        </q-btn>
                        <q-btn v-if="token.group == 'admin'" round color="purple" icon="mdi-calculator"
                            @click="abrirDialog()">
                            <q-tooltip>
                                Registrar
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>

        </div>

        <q-table class="shadow-0 header-tabla q-mt-md" :columns="columns" row-key="_id" :data="itemsEstadoDeResultados"
            hide-bottom hide-header :rows-per-page-options="[0]" :pagination.sync="pagination" :loading="loading">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">

                <q-tr :props="props">
                    <q-td key="signo" :props="props">
                        <!-- {{ props.row.signo }} -->
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ props.row.signo }}</b>
                        <span v-else>{{ props.row.signo }}</span>
                    </q-td>
                    <q-td key="descripcion" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ props.row.descripcion }}</b>
                        <span v-else>{{ props.row.descripcion }}</span>
                    </q-td>
                    <q-td key="importe" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ formatCurrency(props.row.importe) }}</b>
                        <span v-else>{{ formatCurrency(props.row.importe) }}</span>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
  
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import moment from 'moment';
import print from 'print-js'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import EstadoResultados from './EstadoResultados.vue';
import * as XLSX from 'xlsx';
import { EstadoResultadosPDF } from '../PDF/EstadoResultadosPDF.js'

export default {
    name: 'Home',
    components: {
        HelloWorld, Money, EstadoResultados
    },
    data() {
        return {

            itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '2024',
            selectedMesI: { label: 'ENERO', value: 1 },
            selectedMesF: { label: 'DICIEMBRE', value: 12 },
            cliente: { cliente: '' },

            itemsfiltroclientes: null,
            dialogNuevo: false,

            pagination: {
                rowsPerPage: 0
            },
            columns: [
                { name: 'signo', align: 'center', label: 'Signo', field: 'signo', style: 'text-align: left;' },
                { name: 'descripcion', align: 'center', label: 'Descripcion', field: 'descripcion', style: 'text-align: left;' },
                { name: 'importe', align: 'center', label: 'Importe', field: 'importe', style: 'text-align: right;' },
            ],
            itemsEstadoDeResultados: [],
            loading: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsClientes() {
            return this.$store.state.listaClientesStore;
        }
    },
    created() { },
    methods: {
        async GetListEstadoResultados() {
            if (this.cliente.cliente == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un cliente.' })
                return;
            }

            this.$q.loading.show({
                spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140,
                message: 'Consultando, espere...', messageColor: 'white'
            })

            this.itemsEstadoDeResultados = []
            try {
                let response = await axios.get('EstadosFinancieros/GetListEstadoResultados/' + this.selectedAnio + '/' + this.selectedMesI.value + '/' + this.selectedMesF.value + '/' + this.cliente.cliente);
                let respuesta = response.data;
                console.log(respuesta)

                //CREAMOS LA ESTRUCTURA DE LA TABLA
                let salto = { signo: '', descripcion: '', importe: '-' };

                //INGRESOS
                let sumIngresos = respuesta.map(item => item.ingresosNeto).reduce((prev, curr) => prev + curr, 0);
                let objIngresos = { signo: '+', descripcion: 'Ingresos netos', importe: sumIngresos }
                this.itemsEstadoDeResultados.push(objIngresos);

                // COSTOS
                let sumCosto = respuesta.map(item => item.costoVentas).reduce((prev, curr) => prev + curr, 0);
                let objCosto = { signo: '-', descripcion: 'Costo de ventas', importe: sumCosto }
                this.itemsEstadoDeResultados.push(objCosto);

                //UTILIDAD BRUTA
                let objUtilidadBruta = { signo: '=', descripcion: 'UTILIDAD BRUTA', importe: sumIngresos - sumCosto }
                this.itemsEstadoDeResultados.push(objUtilidadBruta);
                this.itemsEstadoDeResultados.push(salto);

                //GASTOS GENERALES
                let sumGastosGenerales = respuesta.map(item => item.gastosGenerales).reduce((prev, curr) => prev + curr, 0);
                let objGastosGenerales = { signo: '-', descripcion: 'Gastos generales', importe: sumGastosGenerales }
                this.itemsEstadoDeResultados.push(objGastosGenerales);

                //UTILIDAD DE OPERACION
                let objUtilidadDeOperacion = { signo: '=', descripcion: 'UTILIDAD DE OPERACIÓN', importe: objUtilidadBruta.importe - sumGastosGenerales }
                this.itemsEstadoDeResultados.push(objUtilidadDeOperacion);
                this.itemsEstadoDeResultados.push(salto);

                //RESULTADO INTEGRAL DE FINANCIAMIENTO
                let sumResultadoIntegral = respuesta.map(item => item.resultadoIntegralFinanciamiento).reduce((prev, curr) => prev + curr, 0);
                let objResultadoIntegral = { signo: '-', descripcion: 'Resultado integral de financiamiento', importe: sumResultadoIntegral }
                this.itemsEstadoDeResultados.push(objResultadoIntegral);

                //PARTICIPACIONES
                let sumParticipaciones = respuesta.map(item => item.participacionAsociadas).reduce((prev, curr) => prev + curr, 0);
                let objParticipaciones = { signo: '+', descripcion: 'Participación en asociadas', importe: sumParticipaciones }
                this.itemsEstadoDeResultados.push(objParticipaciones);

                //UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD
                let objUtilidadImpuestos = { signo: '=', descripcion: 'UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD', importe: objUtilidadDeOperacion.importe - sumResultadoIntegral + sumParticipaciones }
                this.itemsEstadoDeResultados.push(objUtilidadImpuestos);
                this.itemsEstadoDeResultados.push(salto);

                //IMPUESTOS A LA UTILIDAD
                let sumImpuestos = respuesta.map(item => item.impuestosUtilidad).reduce((prev, curr) => prev + curr, 0);
                let objImpuestos = { signo: '-', descripcion: 'Impuestos a la utilidad', importe: sumImpuestos }
                this.itemsEstadoDeResultados.push(objImpuestos);

                //UTILIDAD DE OPERACIONES CONTINUAS
                let objUtilidadOperaciones = { signo: '=', descripcion: 'UTILIDAD DE OPERACIONES CONTINUAS', importe: objUtilidadImpuestos.importe - sumImpuestos }
                this.itemsEstadoDeResultados.push(objUtilidadOperaciones);
                this.itemsEstadoDeResultados.push(salto);

                //OPERACIONES DISCONTINUADAS
                let sumDiscontinuadas = respuesta.map(item => item.operacionesDiscontinuadas).reduce((prev, curr) => prev + curr, 0);
                let objDiscontinuadas = { signo: '-', descripcion: 'Operaciones discontinuadas', importe: sumDiscontinuadas }
                this.itemsEstadoDeResultados.push(objDiscontinuadas);

                //UTILIDAD DE OPERACIONES CONTINUAS
                let desc_ = 'UTILIDAD NETA'
                if (objUtilidadOperaciones.importe - sumDiscontinuadas < 0) {
                    desc_ = 'PÉRDIDA NETA'
                }
                let objUtilidadNeta = { signo: '=', descripcion: desc_, importe: objUtilidadOperaciones.importe - sumDiscontinuadas }
                this.itemsEstadoDeResultados.push(objUtilidadNeta);

                this.$q.loading.hide()

            } catch (error) {
                console.log(error)
                this.$q.loading.hide()

            }
        },

        abrirDialog() {
            this.dialogNuevo = true
        },
        formatCurrency(value) {
            if (value != '-') {
                // let val = (value/1).toFixed(2)
                // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                return '';
            }
        },
        async ExportarExcel() {
            const hojaCalculo = XLSX.utils.json_to_sheet(this.itemsEstadoDeResultados);
            const libroTrabajo = XLSX.utils.book_new();

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'ESTADO DE RESULTADOS  DE ' + this.selectedMesI.label + ' AL ' + this.selectedMesF.label + ' DEL ' + this.selectedAnio

            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },
        async ExportarPDF() {

            const nombreArchivo = 'DE ' + this.selectedMesI.label + ' AL ' + this.selectedMesF.label + ' DEL ' + this.selectedAnio
            const pdfBase64 = await EstadoResultadosPDF(this.itemsEstadoDeResultados, this.cliente.cliente, nombreArchivo,);
            const fileName = 'Estado_de_Resultados_de_' + this.selectedMesI.label + ' AL ' + this.selectedMesF.label + ' DEL ' + this.selectedAnio + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },
        filtroClientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroclientes = this.itemsClientes.filter(v => v.cliente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroclientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    }
}
</script>
  