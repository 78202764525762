<template>
    <q-card style="width: 1100px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Estado de situación financiera
                </q-toolbar-title>
                <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="PostEstadoSituacionFinanciera()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>CLIENTE</div>
                            <q-space />
                        </q-bar>
                    </div>
                    <div class="col-12 col-md-12">
                        <q-select v-model="cliente" emit-value map-options @filter="filtroClientes" option-label="cliente"
                            :options="itemsfiltroclientes" use-input input-debounce="0" dense filled label="Cliente"
                            virtual-scroll-slice-size="1" @input="GetEstadoSituacionFinanciera()" />
                    </div>
                    <template v-if="cliente.cliente != ''">
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>FECHA</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <div class="col-12 col-md-5">
                            <q-select filled dense v-model="selectedAnio" @input="GetEstadoSituacionFinanciera()"
                                :options="itemsAnios" label="Año" />
                        </div>
                        <div class="col-12 col-md-7">
                            <q-select filled dense v-model="selectedMes" @input="GetEstadoSituacionFinanciera()"
                                :options="itemsMes" label="Mes" />
                        </div>
                    </template>
                    <template v-if="selectedAnio != '' && selectedMes != null">
                        <div class="col-12 col-md-6">
                            <div class="row q-col-gutter-sm">
                                <div class="col-12">
                                    <q-bar dense class="bg-primary text-white">
                                        <q-space />
                                        <div>ACTIVOS</div>
                                        <q-space />
                                    </q-bar>
                                    <q-bar dense class="bg-white text-blue">
                                        <q-space />
                                        <div>ACTIVOS A CORTO PLAZO</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Efectivos y equivalentes de efectivo"
                                        v-model="estadoSituacion.activoCortoPlazo.efectivo">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Cuentas por cobrar"
                                        v-model="estadoSituacion.activoCortoPlazo.cuentasCobrar">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Pagos Anticipados"
                                        v-model="estadoSituacion.activoCortoPlazo.pagosAnticipados">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Otros Activos a corto plazo"
                                        v-model="estadoSituacion.activoCortoPlazo.otrosActivos">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-bar dense class="bg-white text-blue">
                                        <q-space />
                                        <div>ACTIVOS A LARGO PLAZO</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Propiedades, Planta y Equipo"
                                        v-model="estadoSituacion.activoLargoPlazo.propiedades">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Activos intangibles"
                                        v-model="estadoSituacion.activoLargoPlazo.activosIntangibles">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="row q-col-gutter-sm">
                                <div class="col-12">
                                    <q-bar dense class="bg-primary text-white">
                                        <q-space />
                                        <div>PASIVOS</div>
                                        <q-space />
                                    </q-bar>
                                    <q-bar dense class="bg-white text-blue">
                                        <q-space />
                                        <div>PASIVOS A CORTO PLAZO</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Proveedores"
                                        v-model="estadoSituacion.pasivoCortoPlazo.proveedores">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Obligaciones acomuladas"
                                        v-model="estadoSituacion.pasivoCortoPlazo.obligaciones">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Retenciones de efectivo y cobros por cuenta de terceros"
                                        v-model="estadoSituacion.pasivoCortoPlazo.retencionesEfectivo">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Otros pasivos a corto plazo"
                                        v-model="estadoSituacion.pasivoCortoPlazo.otrosPasivos">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-bar dense class="bg-primary text-white">
                                        <q-space />
                                        <div>CAPITAL CONTABLE</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Capital contable"
                                        v-model="estadoSituacion.capitalContable">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-bar dense class="bg-primary text-white">
                                        <q-space />
                                        <div>CAPITAL CONTRIBUIDO</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Capital contable"
                                        v-model="estadoSituacion.capitalContribuido">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-bar dense class="bg-primary text-white">
                                        <q-space />
                                        <div>CAPITAL GANADO</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Capital ganado" v-model="estadoSituacion.capitalGanado">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-bar dense class="bg-primary text-white">
                                        <q-space />
                                        <div>UTILIDAD DEL EJERCICIO</div>
                                        <q-space />
                                    </q-bar>
                                </div>
                                <div class="col-12">
                                    <q-field dense filled label="Utilidad del ejercicio"
                                        v-model="estadoSituacion.utilidadEjercicio">
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>
                                    </q-field>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money,
    },
    data() {
        return {
            cliente: { cliente: '' },
            itemsfiltroclientes: null,

            itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '',
            selectedMes: null,

            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },

            estadoSituacion: {
                mes: '',
                anio: '',
                cliente: '',
                valueMes: 0,
                activoCortoPlazo: {
                    efectivo: 0,
                    cuentasCobrar: 0,
                    pagosAnticipados: 0,
                    otrosActivos: 0,
                },
                activoLargoPlazo: {
                    propiedades: 0,
                    activosIntangibles: 0,
                },
                pasivoCortoPlazo: {
                    proveedores: 0,
                    obligaciones: 0,
                    retencionesEfectivo: 0,
                    otrosPasivos: 0,
                },
                capitalContable: 0,
                capitalContribuido: 0,
                capitalGanado: 0,
                utilidadEjercicio: 0
            }
        }
    },
    computed: {
        itemsClientes() {
            return this.$store.state.listaClientesStore;
        }
    },

    created() {
    },
    methods: {
        async PostEstadoSituacionFinanciera() {
            if (this.cliente.cliente == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un cliente.' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            this.estadoSituacion.mes = this.selectedMes.label
            this.estadoSituacion.anio = this.selectedAnio
            this.estadoSituacion.valueMes = this.selectedMes.value
            this.estadoSituacion.cliente = this.cliente.cliente
            console.log(this.estadoSituacion)
            try {
                let response = await axios.post('EstadosFinancieros/PostEstadoSituacionFinanciera/', this.estadoSituacion)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async GetEstadoSituacionFinanciera() {
            if (this.selectedAnio == '' || this.selectedMes == null) {
                return
            }
            if (this.cliente.cliente == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un cliente.' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })

            try {
                let response = await axios.get('EstadosFinancieros/GetEstadoSituacionFinanciera/' + this.selectedAnio + '/' + this.selectedMes.label + '/' + this.cliente.cliente)
                console.log(response.data)
                if (response.data != '') {
                    this.estadoSituacion = response.data
                } else {
                    this.estadoSituacion = {
                        mes: '',
                        anio: '',
                        cliente: '',
                        valueMes: 0,
                        activoCortoPlazo: {
                            efectivo: 0,
                            cuentasCobrar: 0,
                            pagosAnticipados: 0,
                            otrosActivos: 0,
                        },
                        activoLargoPlazo: {
                            propiedades: 0,
                            activosIntangibles: 0,
                        },
                        pasivoCortoPlazo: {
                            proveedores: 0,
                            obligaciones: 0,
                            retencionesEfectivo: 0,
                            otrosPasivos: 0,
                        },
                        capitalContable: 0,
                        capitalContribuido: 0,
                        capitalGanado: 0,
                        utilidadEjercicio: 0
                    }
                }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        filtroClientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroclientes = this.itemsClientes.filter(v => v.cliente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroclientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    }
}
</script>
<style></style>