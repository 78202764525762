<template>
    <div v-if="token">
        <q-img v-if="token" class="" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
            <div class="absolute-bottom bg-transparent">
                <q-avatar size="56px" class="q-mb-sm">
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                </q-avatar>
                <div class="text-weight-bold">{{ this.token.username }}</div>
                <div class="text-weight-bold">{{ this.token.nombre + ' ' + this.token.apellido }}</div>
            </div>
        </q-img>

        <q-list padding>
            <q-separator></q-separator>
            <q-item clickable v-ripple @click="irCliente()">
                <q-item-section avatar>
                    <q-icon name="mdi-account-group-outline" />
                </q-item-section>
                <q-item-section>
                    Clientes
                </q-item-section>
            </q-item>

            <q-separator></q-separator>
            <q-item v-if="token.group == 'admin'" clickable v-ripple @click="irEmpleados()">
                <q-item-section avatar>
                    <q-icon name="mdi-account-group" />
                </q-item-section>
                <q-item-section>
                    Usuarios
                </q-item-section>
            </q-item>

            <q-separator />
            <q-item tag="label" v-ripple>
                <q-item-section>
                    <q-item-label>Modo Oscuro</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-toggle color="blue" :value="modoOscuro" v-model="isDarkMode" val="battery" />
                </q-item-section>
            </q-item>

            <q-separator />

            <q-item clickable active v-ripple @click="salir()">
                <q-item-section>
                    Cerrar Sesión
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="mdi-logout" />
                </q-item-section>
            </q-item>
        </q-list>
    </div>
</template>
<script>
import { ref } from 'vue'
import axios from "axios";

export default {

    components: {
    },
    data() {
        return {
            isDarkMode: false,
            modoOscuro: true
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {
        async salir() {
            this.$q.loading.show({ message: '<b>Cerrando Sesión...</b>' })
            try {
                this.$q.loading.hide()
                this.$store.dispatch("salir");
            } catch (err) {
                console.log(err);
                this.$q.loading.hide()

            }
        },

        irEmpleados() {
            this.$router.push('ListaUsuarios')
        },

        irCliente() {
            this.$router.push('ListaClientes')
        }
    }
}
</script>
  
