<template>
    <div class="fondo window-height window-width row justify-center items-center">

        <div class="column">
            <div class="row">
                <!-- FORMULARIO PARA INICIAR SESION -->
                <q-card style="background-color: #f3f3f3;" class=" q-pa-lg shadow-0">

                    <q-card-section>
                        <q-form class="q-gutter-md q-my-lg">
                            <span style="color: #A2A2A2; font-size: 20px;">Estados Financieros</span>
                            <!-- <img alt="Contago logo" src="../assets/icono-logo.png" style="height: 100px"> -->
                            <q-input filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input filled v-model="password" type="password" label="Contraseña"
                                @keyup.enter="validarUsuario()" />
                        </q-form>
                    </q-card-section>
                    <q-card-actions class="q-px-md">
                        <q-btn unelevated style="background-color: #213343; color: aliceblue;" size="lg" class="full-width "
                            @click="validarUsuario()" label="INICIAR SESIÓN" />
                    </q-card-actions>
                </q-card>

            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import { QSpinnerGears } from 'quasar';
export default {
    name: 'Login',
    data() {
        return {
            password: '',
            nombreU: "",
        }
    },
    computed: {
    },
    methods: {
        async validarUsuario() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })

            let respuesta = await axios.get("Login/ValidaLogin/dev_ventas/" + this.nombreU + "/" + this.password + '/estados').then((response) => {
                this.$q.loading.hide()
                return response.data;
            })
                .then((data) => {
                    console.log(data)
                    this.$store.state.usuario = { ...data.usuario };
                    this.$q.loading.hide()
                    this.$store.dispatch("guardarToken", data.token);
                    this.nombreU = "";
                    this.password = "";
                })
                .catch((err) => {
                    console.log(err);
                    this.$q.notify({ type: 'negative', message: err.response.data })
                    this.$q.loading.hide()
                });

        }
    }
}
</script>
  
<style >
.q-card {
    width: 500px;
}


.fondo {
    background-image: url('../assets/logo-login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    /* Centra la imagen en el fondo */
    background-position: center;
    /* Establece un color de fondo de respaldo en caso de que la imagen no se cargue o no esté disponible */
    /* Establece una altura mínima para asegurarse de que la imagen cubra todo el contenido */
    min-height: 100vh;
    /* Ajusta otros estilos según sea necesario */
    margin: 0;
}
</style>
