<template>
    <q-card style="width: 600px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Estado de resultados
                </q-toolbar-title>
                <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm" @click="PostEstadoResultados()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>CLIENTE</div>
                            <q-space />
                        </q-bar>
                    </div>
                    <div class="col-12 col-md-12">
                        <q-select v-model="cliente" emit-value map-options @filter="filtroClientes" option-label="cliente"
                            :options="itemsfiltroclientes" use-input input-debounce="0" dense filled label="Cliente"
                            virtual-scroll-slice-size="1" @input="GetEstadoResultado()" />
                    </div>
                    <template v-if="cliente.cliente != ''">
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>FECHA</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <div class="col-12 col-md-5">
                            <q-select filled dense v-model="selectedAnio" @input="GetEstadoResultado()"
                                :options="itemsAnios" label="Año" />
                        </div>
                        <div class="col-12 col-md-7">
                            <q-select filled dense v-model="selectedMes" @input="GetEstadoResultado()" :options="itemsMes"
                                label="Mes" />
                        </div>
                    </template>

                    <template v-if="selectedAnio != '' && selectedMes != null">
                        <div class="col-12">
                            <q-field dense filled label="Ingresos Neto" v-model="estadoResultados.ingresosNeto">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-field dense filled label="Costo de ventas" v-model="estadoResultados.costoVentas">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-field dense filled label="Gastos generales" v-model="estadoResultados.gastosGenerales">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-field dense filled label="Resultado integral de financiamiento"
                                v-model="estadoResultados.resultadoIntegralFinanciamiento">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-field dense filled label="Participación en asociadas"
                                v-model="estadoResultados.participacionAsociadas">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-field dense filled label="Impuestos a la utilidad"
                                v-model="estadoResultados.impuestosUtilidad">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-field dense filled label="Opercaciones discontinuadas"
                                v-model="estadoResultados.operacionesDiscontinuadas">
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                    </template>

                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money,
    },
    data() {
        return {
            itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '',
            selectedMes: null,

            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },
            estadoResultados: {
                mes: '',
                anio: '',
                valueMes: '',
                ingresosNeto: 0,
                costoVentas: 0,
                gastosGenerales: 0,
                resultadoIntegralFinanciamiento: 0,
                participacionAsociadas: 0,
                impuestosUtilidad: 0,
                operacionesDiscontinuadas: 0,
                cliente: ''
            },
            cliente: {
                cliente: ''
            },
            itemsfiltroclientes: null,

        }
    },
    computed: {
        itemsClientes() {
            return this.$store.state.listaClientesStore;
        }
    },

    created() {
    },
    methods: {
        async PostEstadoResultados() {
            if (this.cliente.cliente == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un cliente.' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos. Espere...', messageColor: 'white' })

            this.estadoResultados.mes = this.selectedMes.label
            this.estadoResultados.anio = this.selectedAnio
            this.estadoResultados.valueMes = this.selectedMes.value
            this.cliente = this.cliente.cliente
            console.log(this.estadoResultados)
            try {
                let response = await axios.post('EstadosFinancieros/PostEstadoResultados/', this.estadoResultados)
                console.log(response)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Los datos se han registrado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async GetEstadoResultado() {

            if (this.selectedAnio == '' || this.selectedMes == null) {
                return
            }
            if (this.cliente.cliente == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un cliente.' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Obteniendo datos. Espere...', messageColor: 'white' })

            try {
                let response = await axios.get('EstadosFinancieros/GetEstadoResultado/' + this.selectedAnio + '/' + this.selectedMes.label + '/' + this.cliente.cliente)
                console.log(response)
                if (response.data != '') {
                    this.estadoResultados = response.data
                } else {
                    this.estadoResultados = {
                        mes: '',
                        anio: '',
                        valueMes: '',
                        ingresosNeto: 0,
                        costoVentas: 0,
                        gastosGenerales: 0,
                        resultadoIntegralFinanciamiento: 0,
                        participacionAsociadas: 0,
                        impuestosUtilidad: 0,
                        operacionesDiscontinuadas: 0,
                        cliente: ''
                    }
                }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        filtroClientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroclientes = this.itemsClientes.filter(v => v.cliente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroclientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    }
}
</script>
<style></style>