import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './quasar'

Vue.config.productionTip = false
//axios.defaults.baseURL = "https://localhost:44325/api/"
axios.defaults.baseURL = "https://appapi.contago.com.mx/api/"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
