<template>
    <q-page>

        <!-- DIALOGO PARA CREAR UN NUEVO -->
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <EstadoSituacionFinanciera @cierraVentana="dialogNuevo = false"></EstadoSituacionFinanciera>
        </q-dialog>

        <div class="row q-col-gutter-sm j">
            <div class="col-12 col-md-4">
                <q-select v-model="cliente" emit-value map-options @filter="filtroClientes" option-label="cliente"
                    :options="itemsfiltroclientes" use-input input-debounce="0" dense filled label="Cliente"
                    virtual-scroll-slice-size="1" />
            </div>
            <div class=" col-12 col-md-2">
                <q-select filled dense v-model="selectedAnio" :options="itemsAnios" label="Año" />
            </div>
            <div class=" col-12 col-md-2">
                <q-select filled dense v-model="selectedMesI" :options="itemsMes" label="Mes inicial" />
            </div>
            <div class=" col-12 col-md-4">
                <q-select filled dense v-model="selectedMesF" :options="itemsMes" label="Mes final">
                    <template v-slot:after>
                        <q-btn round color="primary" icon="mdi-magnify" @click="GetListEstadoSituacionFinanciera()">
                            <q-tooltip>
                                Consultar
                            </q-tooltip>
                        </q-btn>
                        <q-btn round color="green" icon="mdi-microsoft-excel" @click="ExportExcel()">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn round color="red" icon="mdi-file-pdf-box" @click="ExportarPdf()">
                            <q-tooltip>
                                PDF
                            </q-tooltip>
                        </q-btn>
                        <q-btn v-if="token.group == 'admin'" round color="purple" icon="mdi-calculator"
                            @click="abrirDialog()">
                            <q-tooltip>
                                Registrar
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-select>
            </div>
        </div>

        <!-- lISTA DE ESTADO DE SITUACION FINANCIERA -->
        <div class="row q-col-gutter-sm q-mt-md">

            <div class="col-12 col-md-6">
                <q-bar dense class="bg-primary text-white">
                    <q-space />
                    <div>ACTIVOS</div>
                    <q-space />
                </q-bar>

                <q-list class="rounded-borders">
                    <!-- ACTIVO A CORTO PLAZO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Activo a corto plazo
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumActivoCortoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsActivoCortoPlazo"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- ACTIVO A LARGO PLAZO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Activo a largo plazo
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumActivoLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsActivoLargoPlazo"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                </q-list>
            </div>

            <div class="col-12 col-md-6">
                <q-bar dense class="bg-primary text-white">
                    <q-space />
                    <div>PASIVOS</div>
                    <q-space />
                </q-bar>

                <q-list class="rounded-borders">
                    <!-- PASIVO A CORTO PLAZO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Pasivo a corto plazo
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumPasivoCortoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsPasivoCortoPlazo"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CAPITAL CONTABLE -->
                    <q-bar dense class="bg-primary text-white">
                        <q-space />
                        <div>CAPITAL CONTABLE</div>
                        <q-space />
                    </q-bar>

                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Capital contable
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumCapitalContable) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsCapitalContable"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CAPITAL CONTRIBUIDO -->
                    <q-bar dense class="bg-primary text-white">
                        <q-space />
                        <div>CAPITAL CONTRIBUIDO</div>
                        <q-space />
                    </q-bar>

                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Captial contribuido
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumCapitalContribuido) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsCapitalContribuido" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- CAPITAL GANADO-->
                    <q-bar dense class="bg-primary text-white">
                        <q-space />
                        <div>CAPITAL GANADO</div>
                        <q-space />
                    </q-bar>

                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Capital ganado
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumCapitalGanado) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsCapitalGanado"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>

                    <!-- UTILIDAD DEL EJERCICIO-->
                    <q-bar dense class="bg-primary text-white">
                        <q-space />
                        <div>UTILIDAD DEL EJERCICIO</div>
                        <q-space />
                    </q-bar>

                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Utilidad del ejercicio
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumUtilidadEjercicio) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsUtilidadEjercicio" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                </q-list>
            </div>
        </div>
        <q-footer class="bg-dark" dark elevated style="height: 45px; margin-top: 0px;">
            <q-toolbar>
                <q-space></q-space>
                <div style="font-size: 18px; margin-right: 12px;">Total Activo: </div>
                <div class="text-h6">{{ formatCurrency(sumTotalActivo) }}</div>
                <q-space></q-space>
                <div style="font-size: 18px; margin-right: 12px;">Total Pasivo + Capital: </div>
                <div class="text-h6">{{ formatCurrency(sumTotalPasivoCapital) }}</div>
                <q-space></q-space>
            </q-toolbar>
        </q-footer>
    </q-page>
</template>
  
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import moment from 'moment';
import print from 'print-js'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import * as XLSX from 'xlsx';
import EstadoSituacionFinanciera from './EstadoSituacionFinanciera.vue';
import { EstadoSituacionFinancieraPDF } from '../PDF/EstadoSituacionFinancieraPDF.js'

export default {
    name: 'Home',
    components: {
        HelloWorld, Money, EstadoSituacionFinanciera
    },
    data() {
        return {

            itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '2024',
            selectedMesI: { label: 'ENERO', value: 1 },
            selectedMesF: { label: 'DICIEMBRE', value: 12 },
            dialogNuevo: false,

            itemsActivoCortoPlazo: [],
            itemsActivoLargoPlazo: [],
            itemsPasivoCortoPlazo: [],
            itemsCapitalContable: [],
            itemsCapitalContribuido: [],
            itemsCapitalGanado: [],
            itemsUtilidadEjercicio: [],

            columns: [
                { name: 'descripcion', align: 'center', label: 'Descripcion', field: 'descripcion', style: 'text-align: left;' },
                { name: 'importe', align: 'center', label: 'Importe', field: 'importe', style: 'text-align: right;' },
            ],

            pagination: {
                rowsPerPage: 0
            },

            expansion: true,
            cliente: { cliente: '' },
            itemsfiltroclientes: null,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        sumActivoLargoPlazo() {
            return this.itemsActivoLargoPlazo.reduce((a, b) => a + b.importe, 0);
        },

        sumActivoCortoPlazo() {
            return this.itemsActivoCortoPlazo.reduce((a, b) => a + b.importe, 0);
        },

        sumPasivoCortoPlazo() {
            return this.itemsPasivoCortoPlazo.reduce((a, b) => a + b.importe, 0);
        },

        sumCapitalContable() {
            return this.itemsCapitalContable.reduce((a, b) => a + b.importe, 0);
        },

        sumCapitalContribuido() {
            return this.itemsCapitalContribuido.reduce((a, b) => a + b.importe, 0);
        },

        sumCapitalGanado() {
            return this.itemsCapitalGanado.reduce((a, b) => a + b.importe, 0);
        },

        sumUtilidadEjercicio() {
            return this.itemsUtilidadEjercicio.reduce((a, b) => a + b.importe, 0);
        },

        sumTotalActivo() {
            let a = this.itemsActivoCortoPlazo.reduce((a, b) => a + b.importe, 0);
            let b = this.itemsActivoLargoPlazo.reduce((a, b) => a + b.importe, 0);

            return a + b;
        },

        sumTotalPasivoCapital() {
            let a = this.itemsPasivoCortoPlazo.reduce((a, b) => a + b.importe, 0);
            let b = this.itemsCapitalContable.reduce((a, b) => a + b.importe, 0);
            let c = this.itemsCapitalContribuido.reduce((a, b) => a + b.importe, 0);
            let d = this.itemsCapitalGanado.reduce((a, b) => a + b.importe, 0);
            let e = this.itemsUtilidadEjercicio.reduce((a, b) => a + b.importe, 0);
            return a + b + c + d + e;
        },
        itemsClientes() {
            return this.$store.state.listaClientesStore;
        }
    },
    created() { },
    methods: {
        abrirDialog() {
            this.dialogNuevo = true
        },
        async GetListEstadoSituacionFinanciera() {
            if (this.cliente.cliente == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un cliente.' })
                return;
            }
            this.itemsActivoCortoPlazo = []
            this.itemsActivoLargoPlazo = []
            this.itemsPasivoCortoPlazo = []
            this.itemsCapitalContable = []
            this.itemsCapitalContribuido = []
            this.itemsCapitalGanado = []
            this.itemsUtilidadEjercicio = []
            try {
                let response = await axios.get('EstadosFinancieros/GetListEstadoSituacionFinanciera/' + this.selectedAnio + '/' + this.selectedMesI.value + '/' + this.selectedMesF.value + '/' + this.cliente.cliente);
                let respuesta = response.data;

                console.log(respuesta)

                // for (let a of respuesta) {
                let objeto1 = {
                    descripcion: "Efectivos y equivalentes de efectivo",
                    importe: respuesta.reduce((a, b) => a + b.activoCortoPlazo.efectivo, 0)
                }

                let objeto2 = {
                    descripcion: "Cuentas por cobrar",
                    importe: respuesta.reduce((a, b) => a + b.activoCortoPlazo.cuentasCobrar, 0)
                }

                let objeto3 = {
                    descripcion: "Pagos Anticipados",
                    importe: respuesta.reduce((a, b) => a + b.activoCortoPlazo.pagosAnticipados, 0)
                }

                let objeto4 = {
                    descripcion: "Otros Activos a corto plazo",
                    importe: respuesta.reduce((a, b) => a + b.activoCortoPlazo.otrosActivos, 0)
                }

                let objeto5 = {
                    descripcion: "Propiedades, Planta y Equipo",
                    importe: respuesta.reduce((a, b) => a + b.activoLargoPlazo.propiedades, 0)
                }

                let objeto6 = {
                    descripcion: "Activos intangibles",
                    importe: respuesta.reduce((a, b) => a + b.activoLargoPlazo.activosIntangibles, 0)
                }

                let objeto7 = {
                    descripcion: "Proveedores",
                    importe: respuesta.reduce((a, b) => a + b.pasivoCortoPlazo.proveedores, 0)
                }

                let objeto8 = {
                    descripcion: "Obligaciones acumuladas",
                    importe: respuesta.reduce((a, b) => a + b.pasivoCortoPlazo.obligaciones, 0)
                }

                let objeto9 = {
                    descripcion: "Retenciones de efectivo y cobros por cuenta de terceros",
                    importe: respuesta.reduce((a, b) => a + b.pasivoCortoPlazo.retencionesEfectivo, 0)
                }

                let objeto10 = {
                    descripcion: "Otros pasivos a corto plazo",
                    importe: respuesta.reduce((a, b) => a + b.pasivoCortoPlazo.otrosPasivos, 0)
                }

                let objeto11 = {
                    descripcion: "Capital contable",
                    importe: respuesta.reduce((a, b) => a + b.capitalContable, 0)
                }
                let objeto12 = {
                    descripcion: "Capital Contribuido",
                    importe: respuesta.reduce((a, b) => a + b.capitalContribuido, 0)
                }
                let objeto13 = {
                    descripcion: "Capital ganado",
                    importe: respuesta.reduce((a, b) => a + b.capitalGanado, 0)
                }
                let objeto14 = {
                    descripcion: "Utilidad del Ejercicio",
                    importe: respuesta.reduce((a, b) => a + b.utilidadEjercicio, 0)
                }

                this.itemsActivoCortoPlazo.push(objeto1)
                this.itemsActivoCortoPlazo.push(objeto2)
                this.itemsActivoCortoPlazo.push(objeto3)
                this.itemsActivoCortoPlazo.push(objeto4)

                this.itemsActivoLargoPlazo.push(objeto5)
                this.itemsActivoLargoPlazo.push(objeto6)

                this.itemsPasivoCortoPlazo.push(objeto7)
                this.itemsPasivoCortoPlazo.push(objeto8)
                this.itemsPasivoCortoPlazo.push(objeto9)
                this.itemsPasivoCortoPlazo.push(objeto10)

                this.itemsCapitalContable.push(objeto11)
                this.itemsCapitalContribuido.push(objeto12)
                this.itemsCapitalGanado.push(objeto13)
                this.itemsUtilidadEjercicio.push(objeto14)
                // }



                this.loading = false;
            } catch (error) {
                console.log(error)
            }
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async ExportExcel() {
            const columnsToInclude = ['descripcion', 'importe'];
            const dataActivoLargoPlazo = [...this.itemsActivoCortoPlazo];
            const dataActivoCortoPlazo = [...this.itemsActivoCortoPlazo];
            const dataPasivoCortoPlazo = [...this.itemsPasivoCortoPlazo];
            const dataCapitalContabale = [...this.itemsCapitalContable]
            const dataCapitalContribuido = [...this.itemsCapitalContribuido]
            const dataCapitalGanado = [...this.itemsCapitalGanado]
            const dataUtilidadEjercicio = [...this.itemsUtilidadEjercicio]

            //EXTRAEMOS LOS DATOS QUE NECESITAMOS
            const datosActivoLargoPlazo = dataActivoLargoPlazo.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosActivoCortoPlazo = dataActivoCortoPlazo.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosPasivoCortoPlazo = dataPasivoCortoPlazo.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosCapitalContabale = dataCapitalContabale.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosCapitalContribuido = dataCapitalContribuido.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosCapitalGanado = dataCapitalGanado.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosUtilidadEjercicio = dataUtilidadEjercicio.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );

            //CREAMOS LOS ARRAY DE VALORES, SIN OBJETOS
            const arrayActivoCortoPlazo = datosActivoCortoPlazo.map(objeto => Object.values(objeto));
            const arrayActivoLargoPlazo = datosActivoLargoPlazo.map(objeto => Object.values(objeto));
            const arrayPasivoCortoPlazo = datosPasivoCortoPlazo.map(objeto => Object.values(objeto));
            const arrayCaptitalContable = datosCapitalContabale.map(objeto => Object.values(objeto));
            const arrayCapitalContribuido = datosCapitalContribuido.map(objeto => Object.values(objeto));
            const arrayCapitalGanado = datosCapitalGanado.map(objeto => Object.values(objeto));
            const arrayUtilidadEjercicio = datosUtilidadEjercicio.map(objeto => Object.values(objeto));

            // VALORES DE LAS SUMATORIAS
            const sumaActivo = this.sumActivoCortoPlazo + this.sumActivoLargoPlazo
            const sumaActivoYCapital = this.sumPasivoCortoPlazo + this.sumCapitalContable + this.sumCapitalContribuido + this.sumCapitalGanado + this.sumUtilidadEjercicio;

            // console.log(arrayDeValores)
            const libroTrabajo = XLSX.utils.book_new();

            //CREAMOS LA HOJA CON TODO EL CONTENIDO
            const hojaCalculo = XLSX.utils.aoa_to_sheet([

                ['ACTIVO'],
                ['ACTIVO A CORTO PLAZO', { t: 'n', v: this.sumActivoCortoPlazo, z: '#,##0.00' }],
                ...arrayActivoCortoPlazo.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],

                ['ACTIVO A LARGO PLAZO', { t: 'n', v: this.sumActivoLargoPlazo, z: '#,##0.00' }],
                ...arrayActivoLargoPlazo.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],

                ['TOTAL ACTIVO', { t: 'n', v: sumaActivo, z: '#,##0.00' }],

                [],
                [],
                [],

                ['PASIVO'],
                ['PASIVO A CORTO PLAZO', { t: 'n', v: this.sumPasivoCortoPlazo, z: '#,##0.00' }],
                ...arrayPasivoCortoPlazo.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],
                [],

                ['CAPITAL CONTABLE', { t: 'n', v: this.sumCapitalContable, z: '#,##0.00' }],
                ...arrayCaptitalContable.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],
                [],

                ['CAPITAL CONTRIBUIDO', { t: 'n', v: this.sumCapitalContribuido, z: '#,##0.00' }],
                ...arrayCapitalContribuido.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],
                [],

                ['CAPITAL GANADO', { t: 'n', v: this.sumCapitalGanado, z: '#,##0.00' }],
                ...arrayCapitalGanado.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],
                [],

                ['UTILIDAD DEL EJERCICIO', { t: 'n', v: this.sumUtilidadEjercicio, z: '#,##0.00' }],
                ...arrayUtilidadEjercicio.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),

                [],

                ['TOTAL PASIVO + CAPITAL', { t: 'n', v: sumaActivoYCapital, z: '#,##0.00' }],
            ]);


            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'ESTADO DE SITUACIÓN FINANCIERA DE ' + this.selectedMesI.label + ' A ' + this.selectedMesF.label + ' DEL ' + this.selectedAnio
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },
        filtroClientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroclientes = this.itemsClientes.filter(v => v.cliente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroclientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        async ExportarPdf() {


            let activoCirculante = [...this.itemsActivoCortoPlazo];
            let activoFijo = [...this.itemsActivoCortoPlazo];
            let activoDiferido = [...this.itemsPasivoCortoPlazo];
            let pasivoCirculante = [...this.itemsCapitalContable];
            let pasivoLargoPlazo = [...this.itemsCapitalContribuido];
            let pasivoDiferido = [...this.itemsCapitalGanado];
            let capital = [...this.itemsUtilidadEjercicio];

            const nombreArchivo = 'DE ' + this.selectedMesI.label + ' AL ' + this.selectedMesF.label + ' DEL ' + this.selectedAnio

            const pdfBase64 = await EstadoSituacionFinancieraPDF(activoCirculante, activoFijo, activoDiferido, pasivoCirculante, pasivoLargoPlazo, pasivoDiferido, capital, this.cliente.cliente, nombreArchivo);
            const fileName = 'ESTADO DE SITUACIÓN FINANCIERA DE ' + this.selectedMesI.label + ' A ' + this.selectedMesF.label + ' DEL ' + this.selectedAnio + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },
    }
}
</script>
  