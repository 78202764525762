<template>
    <q-page class=" q-pa-lg ">
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Cliente @cierraVentana="dialogNuevo = false"></Cliente>
        </q-dialog>

        <div class="row	flex-left ">
            <div class="col-12  text-right ">
                <q-btn round color="primary" class="q-mr-sm" icon="mdi-update" @click="GetClientes()" />
                <q-btn v-if="token.group == 'admin'" round color="primary" icon="mdi-plus" @click="nuevoCliente()" />
            </div>
        </div>

        <div class="q-mt-md">
            <q-table title="Clientes" class="shadow-0" :data="items" :columns="columns" row-key="_id" :filter="filter"
                :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn v-if="token.group == 'admin'" size="md" color="primary" rounded flat dense
                                @click="editarCliente(props.row)" icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="representante" :props="props">{{ props.row.representante }}</q-td>
                        <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                        <q-td key="razonSocial" :props="props">{{ props.row.razonSocial }}</q-td>
                        <q-td key="codigoPostal" :props="props">{{ props.row.codigoPostal }}</q-td>
                        <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                        <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
                        <q-td key="direccion" :props="props">{{ props.row.direccion }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Cliente from './Cliente.vue'
export default {
    components: { Cliente },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'representante', align: 'center', label: 'Representante', field: 'representante', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', classes: 'text-center text-dark bg-grey-2 ellipsis', headerClasses: 'text-white bg-primary text-center', },
                { name: 'razonSocial', align: 'center', label: 'Razón Social', field: 'razonSocial', sortable: true, classes: 'text-center text-dark bg-grey-2 ellipsis', headerClasses: 'text-white bg-primary text-center', },
                { name: 'codigoPostal', align: 'center', label: 'Código Postal', field: 'codigoPostal' },
                { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono' },
                { name: 'correo', align: 'center', label: 'Correo', field: 'correo', sortable: true },
                { name: 'direccion', align: 'center', label: 'Dirección', field: 'direccion' },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'nombre',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaClientesStore
        }
    },
    created() {
        this.GetClientes();
    },
    methods: {
        nuevoCliente() {
            let cliente = {
                _id: '',
                representante: '',
                razonSocial: '',
                codigoPostal: '',
                rfc: '',
                direccion: '',
                correo: '',
                telefono: '',
                estatus: 'Vigente',
                app: 'estadosFinancieros'
            }
            this.$store.state.clienteStore = cliente
            this.dialogNuevo = true
        },
        async GetClientes() {
            this.loading = true
            try {
                let response = await axios.get("ControlVentas/GetClientes/estadosFinancieros");
                let catalogo = response.data;
                this.$store.state.listaClientesStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        editarCliente(data) {
            console.log(data)
            data.password = ''
            this.$store.state.clienteStore = { ...data }

            this.dialogNuevo = true
        },

    }
}
</script>
    
<style></style>
<style lang="sass">
    </style>
    