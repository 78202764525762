<template>
    <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Cliente
                </q-toolbar-title>
                <q-btn v-if="cliente._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="PutCliente()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-input v-model="cliente.representante" dense filled label="Representante" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="cliente.codigoPostal" dense filled label="Código Postal" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="cliente.rfc" dense filled label="RFC" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="cliente.razonSocial" dense filled label="Razón Social" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="cliente.telefono" dense filled label="Teléfono" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="cliente.correo" dense filled label="Correo" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="cliente.direccion" dense filled label="Dirección" />
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {
        }
    },
    computed: {
        cliente() {
            return this.$store.state.clienteStore;
        },
    },

    created() {
    },
    methods: {
        async guardar() {

            console.log(this.cliente)

            if (this.cliente.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del cliente' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando cliente. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('ControlVentas/PostCliente/', this.cliente)
                console.log(response)

                this.cliente._id = response.data
                this.$store.state.listaClientesStore.push(this.cliente)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El cliente ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async PutCliente() {
            this.$q.loading.show({
                spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140,
                message: 'Guardando cliente. Espere...', messageColor: 'white'
            })

            if (this.cliente.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del cliente.' })
                return;
            }

            try {
                let response = await axios.put('ControlVentas/PutCliente/', this.cliente)
                console.log(response)

                let indice = this.$store.state.listaClientesStore.findIndex(x => x._id === this.cliente._id);
                Object.assign(this.$store.state.listaClientesStore[indice], this.cliente)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El cliente ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },
    }
}
</script>
<style></style>